import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import { Hub, HubCapsule } from "@aws-amplify/core";
import { useAmplitude } from "@/hooks/useAmplitude";
import { MFACodes } from "@/utils/cognito/MFACodes";

export enum LoginStates {
  LoggedIn,
  LoggedOut,
  Loading,
}

enum AuthEventTypes {
  Login = "signIn",
  Logout = "signOut",
  Signup = "signUp",
  LoginError = "signIn_failure",
  Configured = "configured",
}

interface ProviderProps {
  children: React.ReactNode;
}

type AuthChallengeName =
  | MFACodes.NEW_PASSWORD_REQUIRED
  | MFACodes.SMS_MFA
  | MFACodes.SOFTWARE_TOKEN_MFA
  | MFACodes.MFA_SETUP;

export interface CognitoCustomAttributes {
  "custom:name": string;
  "custom:sign_up_token"?: string;
}

export type AuthCognitoUser = CognitoUser & {
  challengeName?: AuthChallengeName;
  attributes?: CognitoCustomAttributes;
};

interface UserProviderContextState {
  state: LoginStates;
  username?: string;
  email?: string;
  name?: string;
  accessToken?: string;
  identityToken?: string;
  cognitoUser?: AuthCognitoUser;
  MFAEnabled?: boolean;
  setMFAEnabled(value: boolean): void;
  setCognitoUser(user: AuthCognitoUser): void;
}

export const UserStateContext = createContext<
  UserProviderContextState | undefined
>(undefined);

// eslint-disable-next-line max-statements
function UserProvider({ children }: ProviderProps) {
  const [state, setState] = useState<LoginStates>(LoginStates.Loading);
  const [username, setUsername] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string | undefined>(undefined);
  const [cognitoUser, setCognitoUser] = useState<AuthCognitoUser | undefined>(
    undefined,
  );
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [identityToken, setIdentityToken] = useState<string | undefined>(
    undefined,
  );
  const [MFAEnabled, setMFAEnabled] = useState<boolean | undefined>(undefined);
  const { setUser } = useAmplitude();

  useEffect(() => {
    setUser(username);
  }, [username]);

  const setTokens = useCallback((user?: AuthCognitoUser) => {
    if (user) {
      setAccessToken(
        user.getSignInUserSession()?.getAccessToken()?.getJwtToken(),
      );
      setIdentityToken(
        user.getSignInUserSession()?.getIdToken()?.getJwtToken(),
      );
    } else {
      setAccessToken(undefined);
      setIdentityToken(undefined);
    }
  }, []);

  const getMFADetails = useCallback((user?: CognitoUser) => {
    if (user) {
      Auth.getPreferredMFA(user).then(result => {
        setMFAEnabled(result != MFACodes.NO_MFA);
      });
    }
  }, []);

  const setUserDetails = useCallback(
    // eslint-disable-next-line max-statements
    (user?: AuthCognitoUser, tokenRefreshCallback?: TimerHandler) => {
      if (user) {
        const expiration =
          user.getSignInUserSession()?.getAccessToken().getExpiration() || 0;
        if (tokenRefreshCallback) {
          setTimeout(
            tokenRefreshCallback,
            expiration * 1000 - Date.now() + 180000,
          );
        }
        setState(LoginStates.LoggedIn);
        setEmail(user.getSignInUserSession()?.getIdToken().payload?.email);
        setUsername(user.getUsername());
        setTokens(user);
        setCognitoUser(user);
        user.getUserAttributes((_, data) => {
          setName(
            data
              ?.find(attribute => attribute.getName() === "custom:name")
              ?.getValue(),
          );
        });
        if (user.challengeName !== undefined) {
          setMFAEnabled(user.challengeName === MFACodes.SOFTWARE_TOKEN_MFA);
        } else {
          getMFADetails(user);
        }
      } else {
        setState(LoginStates.LoggedOut);
        setUsername(undefined);
        setTokens(undefined);
        setCognitoUser(undefined);
      }
    },
    [],
  );

  const getCurrentSession = useCallback(() => {
    return Auth.currentAuthenticatedUser()
      .then((user: AuthCognitoUser) => {
        setUserDetails(user, getCurrentSession);
      })
      .catch(error => {
        if (error == "The user is not authenticated") {
          setUserDetails(undefined, getCurrentSession);
        }
      });
  }, []);

  // eslint-disable-next-line max-statements
  const authListener = useCallback((data: HubCapsule) => {
    switch (data.payload.event as AuthEventTypes) {
      case AuthEventTypes.Login: {
        const user = data.payload.data as AuthCognitoUser;
        setUserDetails(user);
        break;
      }
      case AuthEventTypes.Logout: {
        setState(LoginStates.LoggedOut);
        setUserDetails(undefined);
        break;
      }
    }
  }, []);

  useEffect(() => {
    getCurrentSession();
  }, []);

  Hub.listen("auth", authListener);

  const value = {
    state,
    username,
    email,
    name,
    accessToken,
    identityToken,
    cognitoUser,
    MFAEnabled,
    setMFAEnabled,
    setCognitoUser,
  };
  return (
    <UserStateContext.Provider value={value}>
      {children}
    </UserStateContext.Provider>
  );
}

function useUserContext() {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserContext };
