import React, { PropsWithChildren } from "react";
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  ServerError,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { navigate } from "gatsby";
import { useUserContext } from "@/context/user";

export function ApolloProviderWrapper({
  children,
}: PropsWithChildren<unknown>) {
  const { accessToken, cognitoUser } = useUserContext();
  // get name and signup token from cognito user attributes
  const name = cognitoUser?.attributes?.["custom:name"];
  const signupToken = cognitoUser?.attributes?.["custom:sign_up_token"];

  const errorLink = onError(({ networkError }) => {
    const statusNetworkError = { ...networkError } as ServerError;
    if (statusNetworkError && statusNetworkError?.statusCode === 403) {
      navigate("/403");
    }
  });

  const httpLink = new HttpLink({
    uri: process.env.GATSBY_JOBBER_DEVELOPER_API_URL,
    headers: accessToken
      ? {
          AUTHORIZATION: `bearer ${accessToken}`,
          ...(name && { "X-COGNITO-NAME": name }),
          ...(signupToken && { "X-COGNITO-SIGNUP-TOKEN": signupToken }),
        }
      : {},
  });

  const client = new ApolloClient({
    link: from([errorLink, httpLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: { errorPolicy: "all" },
      mutate: { errorPolicy: "all" },
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
