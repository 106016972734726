import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { SEO } from "./SEO";

export function SEOLoader() {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          keywords
        }
      }
    }
  `);
  const { title, description, keywords } = data.site.siteMetadata;

  return <SEO title={title} description={description} keywords={keywords} />;
}
